<template>
  <data-list
      ref="dataList"
      class="content md"
      url="/apply-temp"
      query="&sort=id&filter[is_oa]=2"
      entity-name=""
      add-btn="新增应用"
      hide-show-detail
      :action="action"
      :rules="rules"
      :columns="columns"
      :before-update="beforeUpdate"
      :search-key-type="{name: 1}">
    <template v-slot:query="{form}">
      <a-form-model-item label="">
        <a-input v-model="form.name" placeholder="输入应用名称查询"></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:action="{item}">
      <router-link :to="{name: 'Process', params: {id: item.id}}" class="txt-btn" style="margin-left: 8px" @click="setProcess(item)">流程设置</router-link>
    </template>
    <template v-slot:edit="{form}">
      <a-form-model-item label="应用名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入应用名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="应用图标" prop="name">
        <icon-select v-model="form.icon"></icon-select>
      </a-form-model-item>
      <a-form-model-item label="应用类型" prop="type">
        <a-select
            style="width: 100%"
            placeholder="请选择应用类型"
            :options="hrTempTypes"
            v-model="form.type">
        </a-select>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import {hrTempTypes} from "@/common/constant/template";
import {typeForm} from "@/common/hr/form";

export default {
  name: "HRProcess",
  data() {
    return {
      hrTempTypes,
      columns: [
        {title: '应用名称', dataIndex: 'name'},
      ],
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        icon: [{ required: true, message: '请选择应用图标', trigger: 'change' }],
        type: [{ required: true, message: '请选择应用类型', trigger: 'change' }],
      }
    }
  },
  computed: {
    action() {
      return this.$route.meta.action;
    }
  },
  methods: {
    setProcess() {

    },
    beforeUpdate(form, callback) {
      form.is_oa = 2;
      let type = form.type;
      form.detail = JSON.stringify(typeForm[['monk', 'um', 'board', 'ub', 'trans'][type - 1]].items);
      callback(form);
    }
  }
}
</script>

<style scoped>

</style>
